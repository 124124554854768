import React from "react"
import MainLayout from "../components/MainLayout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { details, html } from "../styles/course-details.module.css"

export default function CourseDetails({ data }) {
  const htmlData = data.markdownRemark.html
  const { title, shortTitle } = data.markdownRemark.frontmatter

  return (
    <MainLayout>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>{shortTitle}</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="95% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content ${details}`}>
          <h2>{title}</h2>
          <div
            className={html}
            dangerouslySetInnerHTML={{ __html: htmlData }}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export const query = graphql`
  query CourseDetail($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        hours
        price
        title
        shortTitle
      }
    }
    titleImage: file(relativePath: { eq: "featured/title-3.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
